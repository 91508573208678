<template>
    <aside class="hidden w-52 bg-gray-800 sm:block flex-shrink-0" >
        <div
            class="py-3 text-2xl uppercase text-center tracking-widest  border-b-2 border-gray-800 mb-8 m-3">

            <jet-nav-link :href="route('dashboard')" class="text-white"><img :src="asset('img/bewinkel-logo.png')"/></jet-nav-link>
        </div>

        <nav class="text-sm text-gray-300">
            <ul class="flex flex-col">
                <li class="px-4 py-2 text-xs uppercase tracking-wider text-gray-500 font-bold">
                    Menu
                </li>
                <!-- v-slot="{ isExactActive, href, navigate }"-->
                <!--          <jetinertia-link  v-slot="{ isExactActive, href, navigate } :href="route('dashboard')"  custom>-->
                <jet-nav-link :href="route('dashboard')" :active="route().current('dashboard')">

                    <li class="px-4 cursor-pointer"
                        :class="[route().current('dashboard') ? 'bg-gray-500 text-gray-800' : 'hover:bg-gray-700']">
                        <a class="py-3 flex items-center" @click.passive="navigate">
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 class="h-5 w-5 mr-2"
                                 fill="none"
                                 viewBox="0 0 24 24"
                                 stroke="currentColor">
                                <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                                />
                            </svg>
                            Dashboard
                        </a>
                    </li>
                </jet-nav-link>




                <li v-if="$page.props.user.is_super_admin" class="px-4 py-2 mt-2 text-xs uppercase tracking-wider text-gray-500 font-bold">
                    Super admin
                </li>

                <li  v-if="$page.props.user.is_admin" class="px-4 cursor-pointer hover:bg-gray-700">

                    <jet-nav-link :href="route('admin.page.index')" class="py-2 flex items-center !text-white" :active="route().current('admin.page.index')">
                        <font-awesome-icon icon="cogs" class="h-5 w-5 mr-2"/>
                        CMS
                    </jet-nav-link>
                </li>

                <li  v-if="$page.props.user.is_admin" class="px-4 cursor-pointer hover:bg-gray-700">

                    <jet-nav-link :href="route('admin.highlight.index')" class="py-2 flex items-center !text-white" :active="route().current('admin.highlight.index')">
                        <font-awesome-icon icon="cogs" class="h-5 w-5 mr-2"/>
                        Highlight
                    </jet-nav-link>
                </li>


                <li  v-if="$page.props.user.is_admin" class="px-4 cursor-pointer hover:bg-gray-700">

                    <jet-nav-link :href="route('admin.redirect.index')" class="py-2 flex items-center !text-white" :active="route().current('admin.redirect.index')">
                        <font-awesome-icon icon="cogs" class="h-5 w-5 mr-2"/>
                        Redirects
                    </jet-nav-link>
                </li>

                <li  v-if="$page.props.user.is_admin" class="px-4 cursor-pointer hover:bg-gray-700">

                    <jet-nav-link :href="route('admin.error404.index')" class="py-2 flex items-center !text-white" :active="route().current('admin.error404.index')">
                        <font-awesome-icon icon="cogs" class="h-5 w-5 mr-2"/>
                        404 Errors
                    </jet-nav-link>
                </li>

                <li  v-if="$page.props.user.is_admin" class="px-4 cursor-pointer hover:bg-gray-700">

                    <jet-nav-link :href="route('admin.visitor.index')" class="py-2 flex items-center !text-white" :active="route().current('admin.visitor.index')">
                        <font-awesome-icon icon="cogs" class="h-5 w-5 mr-2"/>Bezoekers
                    </jet-nav-link>
                </li>

                <li  v-if="$page.props.user.is_admin" class="px-4 cursor-pointer hover:bg-gray-700">

                    <jet-nav-link :href="route('admin.contact.index')" class="py-2 flex items-center !text-white" :active="route().current('admin.contact.index')">
                        <font-awesome-icon icon="cogs" class="h-5 w-5 mr-2"/>
                        Contact
                    </jet-nav-link>
                </li>

                <li  v-if="$page.props.user.is_super_admin" class="px-4 cursor-pointer hover:bg-gray-700">

                    <jet-nav-link :href="route('admin.website.index')" class="py-2 flex items-center !text-white" :active="route().current('admin.website.index')">
                        <font-awesome-icon icon="cogs" class="h-5 w-5 mr-2"/>
                        Website
                    </jet-nav-link>
                </li>
            </ul>
        </nav>
    </aside>
</template>

<script>
import {computed} from 'vue'
import {Disclosure, DisclosureButton, DisclosurePanel} from '@headlessui/vue'
import JetNavLink from '@/Jetstream/NavLink.vue'

export default {
    components: {
        JetNavLink,
        Disclosure,
        DisclosureButton,
        DisclosurePanel,
    },
    props: {
        user: Object,
    },
    setup() {

        const isUserManagementActive = computed(() => {
            const names = ['user-list', 'user-detail', 'roles', 'permissions']

            return names.includes(route.name)
        })

        const isUserActive = computed(() => {
            const names = ['user-list', 'user-detail']

            return names.includes(route.name)
        })

        return {
            isUserManagementActive,
            isUserActive,
        }
    },
}
</script>
