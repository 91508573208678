<template>
    <div className="flex min-h-screen">
        <the-sidebar/>

        <div className="flex flex-col flex-grow bg-gray-200">
            <the-header/>

            <div className="mb-auto">
                <main>
                    <slot></slot>
                </main>
            </div>

            <the-footer/>
        </div>
    </div>
</template>

<script>
import TheSidebar from '@/components/layouts/TheSidebar.vue'
import TheHeader from '@/components/layouts/TheHeader.vue'
import TheFooter from '@/components/layouts/TheFooter.vue'
import JetNavLink from '@/Jetstream/NavLink.vue'
export default {
    components: {
        JetNavLink,
        TheSidebar,
        TheHeader,
        TheFooter,
    },
    watch: {
        "$page.props.flash.success": {
            handler() {
                if (this.$page.props.flash.success) {
                    this.$toast.success(this.$page.props.flash.success);
                }
            },
            // immediate: true,
            deep: true,
        },
        "$page.props.flash.error": {
            handler() {
                if (this.$page.props.flash.error) {
                    this.$toast.error(this.$page.props.flash.error);
                }
            },
            // immediate: true,
            deep: true,
        },
        "$page.props.flash.message": {
            handler() {
                if (this.$page.props.flash.message) {
                    this.$toast.info(this.$page.props.flash.message);
                }
            },
            // immediate: true,
            deep: true,
        },
    },
}
</script>
