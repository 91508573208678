<template>
  <footer class="flex justify-between mt-4 px-8 py-4 text-gray-500 border-t-2 text-xs font-bold bg-white">
    <div class="plex space-x-4">
<!--      <a href="#" class="hover:text-indigo-600">User Guide</a>-->
<!--      <a href="#" class="font-bold hover:text-indigo-600">FAQ</a>-->
<!--      <a href="#" class="font-bold hover:text-indigo-600">Support</a>-->
    </div>
    <div>&copy; {{ new Date().getFullYear() }} CL Web B.V. </div>
  </footer>
</template>

<script>
export default {}
</script>
